import { flow } from 'lodash-es';
import { AjaxError } from 'rxjs/ajax';

import { LOAD_STATUS_COMPLETE } from '../../../constants';
import { BaseAction, ErrorAction } from '../../../types';
import {
    CHECK_LOGOUT_STATE,
    ERROR_LOGOUT,
    LOGOUT_REQUEST,
    OAUTH_LOGIN,
    OAUTH_LOGIN_SUCCESS,
    OAUTH_LOGIN_URL,
    OAUTH_LOGOUT_URL,
    OAUTH_REGISTER,
    OAUTH_TOKEN,
} from '../../../types/actionTypes';
import {
    completeReducer,
    createError,
    failureReducer,
    mappedReducer,
    pendingReducer,
    transformResponseToJson,
    typeComplete,
    typeFail,
    typePending,
} from '../../../utils';
import { AuthenticationState, AuthenticationStatus } from '../types';

import { OAuth2TokenDetails } from './types';

const DEFAULT_TOKEN_RENEW_INTERVAL = 24 * 60 * 60; // currently in central is set to 24 hrs
const TO_MILLISECONDS = 1000;
// setTimeout stores timeout value as a signed 32 bit integer internally so large timeouts ~ > 28 days cause overflow and immediately execute
const MAX_TIMEOUT = 2 ** 31 - 1;
const MIN_TIMEOUT = 60 * TO_MILLISECONDS;

export const INITIAL_STATE: AuthenticationState = {
    status: LOAD_STATUS_COMPLETE,
    error: null,
    userId: '',
    userName: '',
    token: '',
    postLoginRedirectPath: '',
    loginRedirectUrl: '',
    logoutRedirectUrl: '',
    renewTimeout: DEFAULT_TOKEN_RENEW_INTERVAL * TO_MILLISECONDS, // in milliseconds
    registerUrl: '',
    oauth: pendingReducer({}),
    authenticationStatus: AuthenticationStatus.Unknown,
    loggedOutState: false,
};

const oauthLoginUrlSuccessReducer = (
    state: AuthenticationState,
    action: BaseAction<string>,
): AuthenticationState => {
    const { payload } = action;
    return completeReducer({
        ...state,
        loginRedirectUrl: payload,
        oauth: completeReducer({}),
    });
};

const oauthLogoutUrlSuccessReducer = (
    state: AuthenticationState,
    action: BaseAction<string>,
): AuthenticationState => {
    const { payload } = action;
    return completeReducer({
        ...state,
        logoutRedirectUrl: payload,
        oauth: completeReducer({}),
    });
};

const oauthRegisterSuccessReducer = (
    state: AuthenticationState,
    action: BaseAction<string>,
): AuthenticationState => {
    const { payload } = action;
    return {
        ...state,
        registerUrl: payload,
        status: LOAD_STATUS_COMPLETE,
        error: null,
        oauth: completeReducer({}),
    };
};

const oauthLoginSuccessReducer = (
    state: AuthenticationState,
    action: BaseAction<{ userId: string; postLoginRedirectPath: string }>,
): AuthenticationState => {
    const {
        payload: { userId, postLoginRedirectPath },
    } = action;

    return completeReducer({
        ...state,
        userId,
        postLoginRedirectPath,
        authenticationStatus: AuthenticationStatus.Authenticated,
        oauth: completeReducer({}),
    });
};

const oauthTokenSuccessReducer = (
    state: AuthenticationState,
    action: BaseAction<OAuth2TokenDetails>,
): AuthenticationState => {
    const { payload } = action;
    const {
        userId,
        userName,
        token,
        expiry_timeout = state.renewTimeout / TO_MILLISECONDS, // Expiry timeout is in seconds
    } = payload;

    let timeout = expiry_timeout * TO_MILLISECONDS;

    if (timeout > MAX_TIMEOUT) {
        timeout = MAX_TIMEOUT;
    }
    if (timeout < MIN_TIMEOUT) {
        timeout = MIN_TIMEOUT;
    }

    return completeReducer({
        ...state,
        userId,
        userName,
        token,
        renewTimeout: timeout,
        authenticationStatus: AuthenticationStatus.Authenticated,
        oauth: completeReducer({}),
        loggedOutState: false,
    });
};

function oauthLoginFailureReducer(
    state: AuthenticationState,
    { payload }: ErrorAction<AjaxError>,
): AuthenticationState {
    let error;
    const { responseType } = payload;
    if (responseType === 'text') {
        // due to response type inconsistency on server
        const parsedResponse = transformResponseToJson(payload);
        error = createError({ payload: { ...payload, message: parsedResponse } });
    } else {
        error = createError({ payload });
    }
    return failureReducer({
        ...state,
        error,
        token: '',
        oauth: failureReducer({ error }),
    });
}

const oauthLoginPendingReducer = (state: AuthenticationState): AuthenticationState => {
    const { oauth } = state;
    return pendingReducer({
        ...state,
        oauth: pendingReducer(oauth),
    });
};

/**
 * We no longer update the authentication status in the logout reducer.
 * This is the job of the oauth actions/reducers (token endpoint).
 * Updating the status here can cause issues in the portal (CENPLAT-1606).
 */
const logoutReducer = (state: AuthenticationState): AuthenticationState =>
    completeReducer({
        ...state,
        token: '',
        oauth: completeReducer({}),
    });

const oauthAuthenticationStatusReducer =
    (authenticationStatus: AuthenticationStatus) =>
    (state: AuthenticationState): AuthenticationState => ({ ...state, authenticationStatus });

/**
 * In initial call for token, set status to Authenticating.
 * On subsequent calls for token, set status to ReAuthenticating.
 */
function oauthAuthenticationReAuthenticatingReducer(
    state: AuthenticationState,
): AuthenticationState {
    const { authenticationStatus } = state;
    if (
        authenticationStatus === AuthenticationStatus.Authenticated ||
        authenticationStatus === AuthenticationStatus.ReAuthenticating
    ) {
        return { ...state, authenticationStatus: AuthenticationStatus.ReAuthenticating };
    }
    return { ...state, authenticationStatus: AuthenticationStatus.Authenticating };
}

function logoutSkeletonStatusReducer(
    state: AuthenticationState,
    actions: BaseAction,
): AuthenticationState {
    const { payload: isLoggedOut } = actions;
    return { ...state, loggedOutState: isLoggedOut };
}

const oauthLoginSuccessPendingReducer = flow(
    oauthLoginPendingReducer,
    oauthAuthenticationStatusReducer(AuthenticationStatus.Authenticating),
);

const oauthLoginSuccessFailureReducer = flow(
    oauthLoginFailureReducer,
    oauthAuthenticationStatusReducer(AuthenticationStatus.NotAuthenticated),
);

const oauthTokenPendingReducer = flow(
    oauthLoginPendingReducer,
    oauthAuthenticationReAuthenticatingReducer,
);

const oauthTokenFailureReducer = flow(
    oauthLoginFailureReducer,
    oauthAuthenticationStatusReducer(AuthenticationStatus.NotAuthenticated),
);

const setOnErrorLogout = oauthAuthenticationStatusReducer(AuthenticationStatus.NotAuthenticated);

export const reducer = mappedReducer(INITIAL_STATE, {
    [typeComplete(LOGOUT_REQUEST)]: logoutReducer,
    [typeComplete(CHECK_LOGOUT_STATE)]: logoutSkeletonStatusReducer,

    [typePending(OAUTH_LOGIN_URL)]: oauthLoginPendingReducer,
    [typeComplete(OAUTH_LOGIN_URL)]: oauthLoginUrlSuccessReducer,
    [typeFail(OAUTH_LOGIN_URL)]: oauthLoginFailureReducer,

    [typePending(OAUTH_LOGOUT_URL)]: oauthLoginPendingReducer,
    [typeComplete(OAUTH_LOGOUT_URL)]: oauthLogoutUrlSuccessReducer,
    [typeFail(OAUTH_LOGOUT_URL)]: oauthLoginFailureReducer,

    [typeFail(OAUTH_LOGIN)]: oauthLoginFailureReducer,

    [typePending(OAUTH_TOKEN)]: oauthTokenPendingReducer,
    [typeComplete(OAUTH_TOKEN)]: oauthTokenSuccessReducer,
    [typeFail(OAUTH_TOKEN)]: oauthTokenFailureReducer,

    [typePending(OAUTH_LOGIN_SUCCESS)]: oauthLoginSuccessPendingReducer,
    [typeComplete(OAUTH_LOGIN_SUCCESS)]: oauthLoginSuccessReducer,
    [typeFail(OAUTH_LOGIN_SUCCESS)]: oauthLoginSuccessFailureReducer,

    [typePending(OAUTH_REGISTER)]: oauthLoginPendingReducer,
    [typeComplete(OAUTH_REGISTER)]: oauthRegisterSuccessReducer,
    [typeFail(OAUTH_REGISTER)]: oauthLoginFailureReducer,

    [ERROR_LOGOUT]: setOnErrorLogout,
});
